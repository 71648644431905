<template>
  <div class="elv-valuation-record-detail">
    <div class="elv-valuation-record-detail-content">
      <div class="elv-valuation-record-detail-content-operation">
        <div class="elv-valuation-record-detail-content-operation-left">
          <TextSelectFilter
            v-model="sortByDateDirection"
            :title="t('common.sort')"
            :options="sortByDateDirectionOptions"
            @change="getDataList()"
          />
          <TextIconSelect
            v-model="currency"
            :title="t('common.currency')"
            :options="currencyList"
            @change="getDataList()"
          />
          <TextSelectFilter
            v-model="remain"
            :title="t('project.costBasis.remain')"
            :options="remainOptions"
            @change="getDataList()"
          />
        </div>
        <div class="elv-valuation-record-detail-content-operation-right">
          <el-button
            v-if="entityStore?.entityDetail?.startingDate"
            class="elv-valuation-record-detail-content-operation-right__export"
            link
            @click="onImportInventory"
          >
            <SvgIcon name="Import-document" width="16" height="16" />
            {{ t('button.import') }}
          </el-button>
        </div>
      </div>
      <div class="elv-valuation-record-detail-content-list">
        <ListTable
          ref="inventoryTableRef"
          :params="matchParams"
          tableHeight="calc(100vh - 440px)"
          :tableData="dataResult"
          :tableLoading="tableLoading"
          @onChangePage="onChangePage"
          @onChangePageSize="onChangePageSize"
        />
      </div>
    </div>

    <UploadCSVDialog
      ref="uploadCSVDialogRef"
      :title="t('title.importInventory')"
      templateSlug="general-inventory"
      templateUrl="file/csv/Elven%20Inventory%20Template.csv"
      :noPermission="false"
      @onSaveImport="onSaveImport"
      @onResetList="resetList"
    />
  </div>
</template>
<script setup lang="ts">
import { map } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import ListTable from './ListTable.vue'
import { ElMessage } from 'element-plus'
import ValuationApi from '@/api/ValuationApi'
import { useEntityStore } from '@/stores/modules/entity'
import TextSelectFilter from '@/components/Base/Filter/TextSelect.vue'
import TextIconSelect from '@/components/Base/Filter/TextIconSelect.vue'
import UploadCSVDialog from '@/pages/Financials/Project/components/UploadCSVDialog.vue'

const entityStore = useEntityStore()

const tableLoading = ref(false)
const inventoryTableRef = useTemplateRef('inventoryTableRef')
const uploadCSVDialogRef = useTemplateRef('uploadCSVDialogRef')
const matchParams = ref({
  page: 1,
  limit: 20
})

const { t } = useI18n()

const dataResult = ref<any>({ list: [], total: 0 })

const entityId = computed(() => {
  return String(entityStore.entityDetail?.entityId)
})

const currency = ref('ALL')

const currencyList = computed(() => {
  const rebuildCurrencyList = map(entityStore.currencyList, (item) => {
    return {
      label: item.showSymbol,
      value: item.symbol,
      icon: item.logo
    }
  })

  return [
    {
      value: 'ALL',
      label: 'All',
      icon: ''
    },
    ...rebuildCurrencyList
  ]
})

const sortByDateDirection = ref('ASC')

const sortByDateDirectionOptions = ref([
  {
    label: 'Older to Newest',
    value: 'ASC'
  },
  {
    label: 'Newest to Oldest',
    value: 'DESC'
  }
])

const remain = ref('ALL')

const remainOptions = ref([
  {
    label: 'All',
    value: 'ALL'
  },
  {
    label: 'Used',
    value: 'USED'
  },
  {
    label: 'Unused',
    value: 'UNUSED'
  }
])

const getDataList = async () => {
  try {
    tableLoading.value = true
    const summaryParams: any = {
      ...matchParams.value
    }

    if (currency.value !== 'ALL') {
      summaryParams.currency = currency.value
    }

    if (sortByDateDirection.value) {
      summaryParams.sortByDateDirection = sortByDateDirection.value
    }

    if (remain.value !== 'ALL') {
      summaryParams.remain = remain.value
    }

    const result = await ValuationApi.getBrokerInventoryList(entityId.value, summaryParams)
    dataResult.value.total = result.data.total
    dataResult.value.list = result.data.list.map((item: any, index: number) => {
      return {
        ...item,
        rowIndex: index
      }
    })
    inventoryTableRef.value?.ref?.setScrollTop(0)
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

const onImportInventory = () => {
  uploadCSVDialogRef.value?.onCheckUploadCSVDialog()
}

const onSaveImport = async (params: any) => {
  try {
    const data = {
      entityFileId: params.entityFileId
    }
    const res = await ValuationApi.importInventory(entityId.value, data)
    uploadCSVDialogRef.value?.onSuccessImport()
    return Promise.resolve(res)
  } catch (error: any) {
    ElMessage.error(error.message)
    return Promise.reject(error)
  } finally {
    uploadCSVDialogRef.value?.onFinallyImport()
  }
}

const resetList = () => {
  matchParams.value.limit = 20
  matchParams.value.page = 1
  getDataList()
}

const onChangePageSize = (limit: number) => {
  matchParams.value.limit = limit
  matchParams.value.page = 1
  getDataList()
}

const onChangePage = (page: number) => {
  matchParams.value.page = page
  getDataList()
}

onMounted(() => {
  getDataList()
})
</script>
<style lang="scss">
.elv-valuation-record-detail {
  .elv-valuation-record-detail-title {
    padding: 15px 24px;
    flex-direction: column;
    align-items: flex-start;

    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .elv-valuation-record-detail-content-operation {
    height: 32px;
    margin-top: 18px;
    margin-bottom: 8px;
    margin-left: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .elv-valuation-record-detail-content-operation-left,
  .elv-valuation-record-detail-content-operation-right {
    display: flex;
    align-items: center;
  }

  .elv-valuation-record-detail-content-operation-right {
    margin-right: 24px;
  }

  .elv-valuation-record-detail-content-operation-right__export.el-button {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    border-radius: 2px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #636b75;
    cursor: pointer;
    margin-left: 8px;

    svg {
      fill: #838d95;
      margin-right: 8px;
    }

    &:hover {
      span {
        color: #1343bf;
        background-color: #fff;
      }

      svg {
        fill: #1343bf;
      }
    }
  }

  .elv-valuation-record-detail-content-list {
    padding: 0 24px;
  }
}
</style>
