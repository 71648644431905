<template>
  <el-table
    ref="tableElRef"
    v-loading.lock="props.loading"
    :row-key="'specificRuleId'"
    :data="tableData"
    style="width: 100%"
    :max-height="tableHeight"
    border
    stripe
    class="elv-costbasis-rule-table"
  >
    <el-table-column :label="t('common.priority')" width="85" class-name="none-padding">
      <template #default="{ row, $index }">
        <PriorityCell :row="row" :index="$index" />
      </template>
    </el-table-column>

    <el-table-column prop="name" :label="t('common.name')" width="190">
      <template #default="{ row }">
        <span class="elv-costbasis-rule-table-row__cell-name">{{ row.name }}</span>
      </template>
    </el-table-column>
    <el-table-column :label="t('common.conditions')" min-width="240">
      <template #default="{ row }">
        <ConditionCell v-if="row.specificRuleId" :params="row" />
      </template>
    </el-table-column>
    <el-table-column :label="t('common.status')" :resizable="false" width="100" class-name="is-center-label">
      <template #default="{ row }">
        <div v-if="row.specificRuleId" class="elv-costbasis-rule-table-row__cell-switch">
          <el-switch
            v-model="row.enable"
            :disabled="row.specificRuleId <= 0"
            @change="onChangeStatus(row, $event as boolean)"
          />
        </div>
      </template>
    </el-table-column>
    <el-table-column :resizable="false" width="120">
      <template #default="{ row }">
        <div v-if="row.specificRuleId && row.specificRuleId !== -1" class="elv-costbasis-rule-table__operation">
          <SvgIcon
            name="sources-edit"
            width="18"
            height="18"
            class="elv-costbasis-rule-table__edit"
            @click="onEditRule(row)"
          />
          <SvgIcon
            name="sources-delete"
            width="18"
            height="18"
            class="elv-costbasis-rule-table__delete"
            @click="onDeleteRule(row)"
          />
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script setup lang="ts">
import Sortable from 'sortablejs'
import PriorityCell from './Cell/PriorityCell.vue'
import ConditionCell from './Cell/ConditionCell.vue'

const props = defineProps({
  ruleList: {
    type: Array,
    default: () => []
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()

const tableData = ref<any[]>([])

const tableElRef = useTemplateRef('tableElRef')

const tableHeight = computed(() => {
  return 'calc(100vh - 450px)'
})

const emit = defineEmits(['onChangeStatus', 'onEditRule', 'onDeleteRule', 'onSortRule'])

const onDeleteRule = (row: any) => {
  emit('onDeleteRule', row)
}

const onEditRule = (row: any) => {
  emit('onEditRule', row)
}

const onChangeStatus = (row: any, status: boolean) => {
  emit('onChangeStatus', row, status)
}

const initSortable = () => {
  nextTick(() => {
    const table = tableElRef.value?.$el.querySelector('.el-table__body-wrapper tbody')
    let originalTableData: any
    Sortable.create(table, {
      animation: 300,
      handle: '.elv-transactions-automation-table-draggable__icon',
      filter: '.not-draggable',
      onMove: (evt) => {
        const { to } = evt
        const newIndex = Array.from(to.children).indexOf(evt.related)
        if (newIndex === 0 || newIndex === table.children.length - 1) {
          return false // 阻止拖动到指定位置
        }
        return true // 允许拖动到其他位置
      },
      onStart: () => {
        console.log('drag start')
        originalTableData = tableData.value.slice()
      },
      onEnd: async ({ newIndex, oldIndex }) => {
        if (oldIndex !== newIndex) {
          try {
            const newTableData = tableData.value.slice()
            newTableData.splice(Number(newIndex), 0, newTableData.splice(Number(oldIndex), 1)[0])
            tableData.value = newTableData
            emit('onSortRule', newTableData)
          } catch (error: any) {
            console.log(error)
            tableData.value = originalTableData
          }
        }
      }
    })
  })
}

const generateRuleList = (ruleList: any) => {
  const result: any[] = []
  result.push({
    specificRuleId: 0,
    priority: 0,
    enable: true,
    name: 'Manual Assignment'
  })
  ruleList.forEach((rule: any) => {
    result.push(rule)
  })
  result.push({
    specificRuleId: -1,
    priority: 100000,
    enable: true,
    name: 'FIFO'
  })
  console.log(result)
  tableData.value = result
  nextTick(() => {
    initSortable()
  })
}

onMounted(() => {
  generateRuleList(props.ruleList)
})

watch(
  () => props.ruleList,
  async (value: any) => {
    generateRuleList(value)
  },
  { deep: true }
)
</script>

<style lang="scss">
.elv-costbasis-rule-table.el-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  --el-fill-color-lighter: #f9fafb;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ced7e0;
  border-radius: 3px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__header {
    .el-table__cell {
      height: 36px;
      padding: 0;
      background: #eef4fb;
      border-bottom-color: #ced7e0;
      border-right-color: #e4e7eb;

      &.is-center-label {
        .cell {
          justify-content: center;
        }
      }

      .cell {
        padding: 0 10px;
        height: 35px;
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
      }
    }
  }

  &.el-table--border {
    &::after,
    &::before {
      display: none;
    }

    .el-table__inner-wrapper {
      &::after {
        background-color: #ced7e0;
      }
    }
  }

  .el-table__border-left-patch {
    display: none;
  }

  .el-switch {
    --el-switch-on-color: #2f63eb;
    --el-switch-off-color: #dde1e6;
    width: 38px;
    height: 18px;
    border-radius: 20px;
  }

  .el-table__row {
    &:not(.elv-rule-table-is-disabled) {
      &.elv-table__row--striped {
        background: #f9fafb;
      }

      &.is-hover-row {
        background: #f5f7fa;
      }

      &:hover td {
        background: transparent;
      }
    }

    &.elv-rule-table-is-disabled {
      background: #f9fafb;
      opacity: 0.6;
    }

    .el-table__cell {
      padding: 0;

      .cell {
        padding: 0 10px;
        min-height: 35px;
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #0e0f11;
        word-break: break-word;

        .elv-costbasis-rule-table-row__cell-action__title {
          color: #000;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-bottom: 4px;
        }

        .elv-costbasis-rule-table-row__cell-action__label {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          white-space: nowrap;
        }

        .elv-costbasis-rule-table-cell-conditions__item-is {
          margin: 0 6px;
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 11px;
          line-height: 14px;
          color: #636b75;

          span:nth-child(2) {
            margin-left: 4px;
          }
        }

        .elv-costbasis-rule-table-row__cell-action__value {
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-left: 4px;
          white-space: nowrap;
        }
      }

      &.none-padding {
        .cell {
          padding: 0px;
        }
      }
    }
  }

  .elv-costbasis-rule-table-row__cell-index {
    color: #aaafb6;
    font-family: 'Barlow';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .elv-costbasis-rule-table-row__cell-name {
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #0e0f11;
  }

  .elv-costbasis-rule-table-row__cell-data {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #0e0f11;
  }

  .elv-costbasis-rule-table-row__cell-action {
    padding: 11px 0;
    display: flex;
    flex-direction: column;

    .elv-costbasis-rule-table-cell-action__item {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .elv-costbasis-rule-table-row__cell-conditions {
    padding: 11px 0;
    display: flex;
    flex-direction: column;

    .elv-costbasis-rule-table-cell-conditions__item {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .elv-costbasis-rule-table-cell-conditions__item-type {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      color: #0e0f11;
    }
  }

  .elv-costbasis-rule-table-row__cell-actions {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #0e0f11;

    p {
      display: flex;
      align-items: center;
    }

    span {
      height: 18px;
      box-sizing: border-box;
      padding: 0 6px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      margin-left: 6px;
      color: #ffffff;
    }
  }

  .elv-costbasis-rule-table-row__cell-reconciliation {
    display: flex;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    p {
      color: #0e0f11;
      margin-left: 4px;
      font-weight: 500;
    }
  }

  .elv-costbasis-rule-table-row__cell-switch {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .elv-costbasis-rule-table-row__cell-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    img {
      width: 18px;
      height: 18px;
      display: block;
      animation: loading-rotate 2s linear infinite;
    }

    svg {
      cursor: pointer;
      fill: #838d95;

      &.is-disabled {
        cursor: not-allowed !important;
        fill: #edf0f3 !important;
      }

      &:hover {
        fill: #1e2024;
      }
    }
  }

  .elv-costbasis-rule-table__operation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    svg {
      cursor: pointer;
      fill: #838d95;

      &.is-disabled {
        cursor: not-allowed !important;
      }

      &:first-of-type {
        margin-right: 10px;
      }

      &:not(.is-disabled):hover {
        fill: #1e2024;
      }
    }
  }
}
</style>
