<template>
  <div class="elv-costbasis-rule">
    <Header :ruleList="ruleList" @onOpenAddRuleDialog="onOpenAddRuleDialog" />
    <ListTable
      :ruleList="ruleList"
      :loading="loading"
      @onChangeStatus="onChangeStatus"
      @onEditRule="onEditRule"
      @onDeleteRule="onDeleteRule"
      @onSortRule="onSortRule"
    />
    <RuleDialog
      ref="ruleDialogRef"
      :title="ruleDialogMode === 'add' ? t('button.addRule') : t('button.editRule')"
      :currentEule="currentRule"
      :model="ruleDialogMode"
      @onAubmit="getRuleList"
    />
    <ElvMessageBox
      ref="confirmDeleteRuleRef"
      :showCancelButton="true"
      :confirmButtonText="t('button.yes')"
      :cancelButtonText="t('button.cancel')"
      :title="t('report.deleteRule')"
      :loading="deleting"
      @onConfirmEvent="onConfirmDeleteRule"
    >
      <template #content>
        <span class="elv-confirm-info-header__title">{{
          t('message.deleteRuleMessage', { name: currentRule.name })
        }}</span></template
      >
    </ElvMessageBox>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
// import { useReportStore } from '@/stores/modules/reports/index'
import { ElMessage } from 'element-plus'
import Header from './Header.vue'
import ListTable from './ListTable.vue'
import RuleDialog from './RuleDialog.vue'
import ValuationApi from '@/api/ValuationApi'

const { t } = useI18n()
const route = useRoute()

const loading = ref(true)
const deleting = ref(false)
const ruleList = ref<any[]>([])
const ruleDialogMode = ref('add')
const currentRule = ref<any>(null)
const ruleDialogRef = useTemplateRef('ruleDialogRef')
const confirmDeleteRuleRef = useTemplateRef('confirmDeleteRuleRef')

// const reportStore = useReportStore()
const onOpenAddRuleDialog = () => {
  ruleDialogMode.value = 'add'
  ruleDialogRef.value?.onCheckRuleDialog()
}

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const getRuleList = async () => {
  loading.value = true
  try {
    const result = await ValuationApi.getSpecificIdRuleList(entityId.value)
    ruleList.value = result.data
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    loading.value = false
  }
}

const onSortRule = async (newRuleList: any) => {
  const specificRuleIds = newRuleList
    .filter((item: any) => item.specificRuleId > 0)
    .map((item: any) => item.specificRuleId)
  try {
    await ValuationApi.updateSpecificIdRuleSort(entityId.value, { specificRuleIds })
    ElMessage.success(t('message.sortSuccess'))
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
    getRuleList()
  }
}

const onChangeStatus = async (row: any, status: string) => {
  try {
    await ValuationApi.switchSpecificIdRuleStatus(entityId.value, row.specificRuleId, { enable: status })
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  }
}

const onEditRule = (row: any) => {
  currentRule.value = row
  ruleDialogMode.value = 'edit'
  ruleDialogRef.value?.onCheckRuleDialog()
}

const onDeleteRule = (row: any) => {
  console.log(row)
  currentRule.value = row
  confirmDeleteRuleRef.value?.onCheckMessageBoxDialog()
}

const onConfirmDeleteRule = async () => {
  try {
    deleting.value = true
    await ValuationApi.deleteSpecificIdRule(entityId.value, currentRule.value.specificRuleId)
    ElMessage.success(t('message.deleteSuccess'))
    confirmDeleteRuleRef.value?.onCheckMessageBoxDialog()
    getRuleList()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    deleting.value = false
  }
}

onMounted(() => {
  getRuleList()
})
</script>
<style lang="scss">
.elv-costbasis-rule-dialog {
  width: 850px;
  min-height: 464px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-costbasis-rule-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 44px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .el-input {
      // width: 802px;
      height: 36px;
      border-radius: 4px;

      &.elv-rule-form-name {
        height: 44px;
      }

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      &.elv-costbasis-rule-form-item-line {
        .el-form-item__label {
          position: relative;
          color: #838d95;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 704px;
            height: 1px;
            background-color: #dde1e6;
          }
        }

        &.condition .el-form-item__label::after {
          width: 728px;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
      }

      .elv-rule-form-reviewed {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;

        .el-switch {
          --el-switch-on-color: #2f63eb;
          --el-switch-off-color: #dde1e6;
          width: 38px;
          height: 18px;
          border-radius: 20px;
          margin-left: 10px;
        }
      }

      .elv-rule-form-condition {
        // width: 802px;
        min-height: 52px;
        box-sizing: border-box;
        padding: 8px;
        background: #f9fafb;
        border-radius: 5px;
        // :nth-last-child(1) {
        //   margin-bottom: 0px;
        // }
        .elv-costbasis-rule-conditions:last-child {
          margin-bottom: 0px;
        }

        .elv-rule-form-condition-title {
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: 100%;
          padding-bottom: 8px;
          border-bottom: 1px solid #edf0f3;
          margin-bottom: 8px;
        }
      }

      .elv-rule-form-event {
        width: 100%;
        height: 94px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        background: #f9fafb;
        border-radius: 5px;

        .elv-rule-form-condition-title {
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: 100%;
          padding-bottom: 8px;
          border-bottom: 1px solid #edf0f3;
          margin-bottom: 8px;
        }

        .elv-journal-type-drawer-category-select {
          box-sizing: border-box;
          height: 44px;
          width: 786px;
          padding: 10px 12px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background: #ffffff;
          border: 1px solid #dde1e6;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          position: relative;
          transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition-property: border, box-shadow;

          > div {
            display: flex;
            flex-direction: column;

            p {
              font-family: 'Plus Jakarta Sans';
              font-weight: 400;
              font-size: 11px;
              line-height: 15px;
              color: #aaafb6;
              margin-bottom: 8px;
            }

            span {
              font-family: 'Plus Jakarta Sans';
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
              color: #0e0f11;
            }
          }

          &:hover {
            cursor: pointer;
            border: 1px solid #5e85eb;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

            svg {
              fill: #5e85eb;
            }
          }

          svg {
            position: absolute;
            right: 12px;
            bottom: 14px;
            fill: #838d95;
            transition: transform 0.3s;
          }
        }
      }
    }

    .el-select {
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-source-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 20px;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;

      &.is-disabled {
        background: #edf0f3;
        color: #838d95;
      }
    }
  }
}

.el-popper.elv-journal-type-drawer-category-popper {
  width: fit-content !important;
  padding: 0px;
  min-height: 280px;
  height: fit-content;
  box-sizing: border-box;

  .elv-transactions-drawer-event-option-title {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    height: 39px;
    background: #ffffff;
    border-bottom: 1px solid #edf0f3;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #636b75;

    span {
      font-weight: 600;
      color: #0e0f11;
    }
  }
}
</style>
