<template>
  <div class="valuation-record">
    <div class="valuation-record-content">
      <div class="valuation-record-content-operation">
        <TextSelectFilter
          v-model="sortByDateDirection"
          :title="t('common.sort')"
          :options="sortByDateDirectionOptions"
          @change="getDataList()"
        />
        <TextIconSelect
          v-model="currency"
          :title="t('common.currency')"
          :options="currencyList"
          @change="getDataList()"
        />
      </div>
      <div class="valuation-record-content-list">
        <ListTable
          ref="purchaseTableRef"
          :params="matchParams"
          tableHeight="calc(100vh - 440px)"
          :tableData="dataResult"
          :tableLoading="tableLoading"
          @onChangePage="onChangePage"
          @onChangePageSize="onChangePageSize"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { map } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import ListTable from './ListTable.vue'
import ValuationApi from '@/api/ValuationApi'
import { useEntityStore } from '@/stores/modules/entity'
import TextSelectFilter from '@/components/Base/Filter/TextSelect.vue'
import TextIconSelect from '@/components/Base/Filter/TextIconSelect.vue'

const entityStore = useEntityStore()

const tableLoading = ref(false)
const purchaseTableRef = useTemplateRef('purchaseTableRef')
const matchParams = ref({
  page: 1,
  limit: 20
})

const { t } = useI18n()

const dataResult = ref<any>({ list: [], total: 0 })

const entityId = computed(() => {
  return String(entityStore.entityDetail?.entityId)
})

const currency = ref('ALL')

const currencyList = computed(() => {
  const rebuildCurrencyList = map(entityStore.currencyList, (item) => {
    return {
      label: item.showSymbol,
      value: item.symbol,
      icon: item.logo
    }
  })

  return [
    {
      value: 'ALL',
      label: 'All',
      icon: ''
    },
    ...rebuildCurrencyList
  ]
})

const sortByDateDirection = ref('ASC')

const sortByDateDirectionOptions = ref([
  {
    label: 'Older to Newest',
    value: 'ASC'
  },
  {
    label: 'Newest to Oldest',
    value: 'DESC'
  }
])

const getDataList = async () => {
  try {
    tableLoading.value = true
    const summaryParams: any = {
      ...matchParams.value
    }

    if (currency.value !== 'ALL') {
      summaryParams.currency = currency.value
    }

    if (sortByDateDirection.value) {
      summaryParams.sortByDateDirection = sortByDateDirection.value
    }

    const result = await ValuationApi.getBrokerPurchaseList(entityId.value, summaryParams)
    dataResult.value.total = result.data.total
    dataResult.value.list = result.data.list.map((item: any, index: number) => {
      return {
        ...item,
        rowIndex: index
      }
    })
    purchaseTableRef.value?.ref?.setScrollTop(0)
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

const onChangePageSize = (limit: number) => {
  matchParams.value.limit = limit
  matchParams.value.page = 1
  getDataList()
}

const onChangePage = (page: number) => {
  matchParams.value.page = page
  getDataList()
}

onMounted(() => {
  getDataList()
})
</script>
<style lang="scss">
.valuation-record {
  &-title {
    padding: 15px 24px;
    flex-direction: column;
    align-items: flex-start;

    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  &-content-operation {
    height: 32px;
    margin-top: 18px;
    margin-bottom: 8px;
    margin-left: 24px;
    display: flex;
  }

  &-content-list {
    padding: 0 24px;
  }
}
</style>
