<template>
  <el-dialog
    v-model="showDialog"
    width="450px"
    align-center
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-valuation-inventory-asset-selection-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-valuation-inventory-asset-selection-dialog-header__title">
        {{ t('valuation.inventory.assetSelection') }}
      </h4>
      <div class="elv-confirm-info-header__close">
        <SvgIcon name="common-close" width="20" height="20" @click="changeDialogStatus" />
      </div>
    </template>
    <div v-loading="dialogLoading" class="elv-valuation-inventory-asset-selection-dialog-content">
      <div class="elv-valuation-inventory-asset-selection-dialog-content-check-type">
        <el-checkbox-group v-model="brokerInventorySelection">
          <el-checkbox :value="'CRYPTO'">{{ t('common.crypto') }}</el-checkbox>
          <el-checkbox :value="'FOREIGN_CURRENCY'">{{ t('common.foreignCurrency') }}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="elv-valuation-inventory-asset-selection-dialog-content-check-message">
        {{ t('message.assetSelectionInfo') }}
      </div>
    </div>
    <template #footer>
      <el-button
        type="primary"
        round
        class="elv-confirm-info-footer__confirm"
        :loading="saveLoading"
        @click="onConfirm"
        >{{ t('button.save') }}</el-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus'
import ValuationApi from '@/api/ValuationApi'
import { useEntityStore } from '@/stores/modules/entity'

const { t } = useI18n()
const entityStore = useEntityStore()

const showDialog = ref(false)
const saveLoading = ref(false)
const dialogLoading = ref(false)
const brokerInventorySelection = ref<string[]>([])

const entityId = computed(() => {
  return String(entityStore.entityDetail?.entityId)
})

/**
 * 切换选择Broker Inventory核算资产弹窗显示状态
 */
const changeDialogStatus = () => {
  showDialog.value = !showDialog.value
}

/**
 * 保存选择Broker Inventory核算资产
 */
const onConfirm = async () => {
  saveLoading.value = true
  try {
    await ValuationApi.setBrokerInventorySelection(entityId.value, {
      brokerInventorySelection: brokerInventorySelection.value
    })
    entityStore.fetchEntityDetail(entityId.value)
    changeDialogStatus()
    ElMessage.success(t('message.success'))
  } catch (error: any) {
    console.error(error)
  } finally {
    saveLoading.value = false
  }
}

watch(
  () => showDialog.value,
  () => {
    if (showDialog.value) {
      brokerInventorySelection.value = entityStore?.entityDetail?.brokerInventorySelection ?? ['CRYPTO']
    }
  },
  { immediate: true }
)

defineExpose({
  changeDialogStatus
})
</script>

<style lang="scss">
.elv-valuation-inventory-asset-selection-dialog {
  height: auto;
  border-radius: 6px;
  box-shadow:
    0 2px 6px rgb(0 0 0 / 5%),
    0 0 1px rgb(0 0 0 / 30%);

  .el-dialog__body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 24px 52px !important;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    height: fit-content;
    box-sizing: border-box;

    .elv-valuation-inventory-asset-selection-dialog-content {
      .elv-valuation-inventory-asset-selection-dialog-content-check-type {
        color: #1e2024;
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .el-checkbox-group {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .el-checkbox__label {
            padding-left: 6px;
          }

          .el-checkbox {
            height: 28px;
            margin-right: 0;
          }
        }
      }

      .elv-valuation-inventory-asset-selection-dialog-content-check-message {
        display: flex;
        align-items: flex-start;
        width: 100%;
        padding-left: 19px;
        box-sizing: border-box;
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        position: relative;

        &::before {
          display: flex;
          width: 4px;
          height: 4px;
          background-color: #636b75;
          border-radius: 50%;
          content: '';
          position: absolute;
          left: 6px;
          top: 8px;
        }
      }
    }
  }

  .el-dialog__header {
    display: flex;
    align-items: center;
    padding: 0px !important;
    border-bottom: 1px solid #edf0f3;
    padding-right: 12px !important;

    .elv-valuation-inventory-asset-selection-dialog-header__title {
      height: 54px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    .elv-confirm-info-header__close {
      padding: 4px;
      cursor: pointer;
    }
  }

  .el-dialog__footer {
    padding-top: 0;
    padding-bottom: 20px;
    text-align: center;

    .el-button {
      width: 117px;
      height: 44px;
      padding: 14px 35px;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      color: #fff;
      background: #1753eb;
      border: 0;
      border-radius: 22px;
    }
  }
}
</style>
