<template>
  <div>
    <div class="elv-record-banner">
      <ExportDataTaskHistoryInfo
        :exportType="exportProgressStatusType.broker"
        :isMarginTop="true"
        :isMarginBottom="false"
      />
      <div class="elv-record-banner-content">
        <Records :list="typeList" @onClick="onClickType" />
        <CostBasisMethod />
      </div>
    </div>

    <el-dialog
      v-model="showDialog"
      width="75%"
      :show-close="true"
      align-center
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      class="elv-record-dialog"
      @closed="onCloseDialog"
    >
      <template #header="{ titleId }">
        <h4 :id="titleId" class="elv-record-dialog-title">{{ transformI18n(getTypeTitle(currentType)) }}</h4>
      </template>
      <Purchase v-if="currentType == 'purchase'" />
      <Inventory v-if="currentType == 'inventory'" />
      <Sales v-if="currentType == 'sales'" />
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import Records from '../components/Record.vue'
import Sales from './components/Sales/Index.vue'
import { $t, transformI18n } from '@/i18n/index'
import Purchase from './components/Purchase/Index.vue'
import Inventory from './components/Inventory/Index.vue'
import { exportProgressStatusType } from '@/config/global'
import CostBasisMethod from './components/CostbasisMethod/Index.vue'
import ExportDataTaskHistoryInfo from '../../components/ExportDataTaskHistoryInfo.vue'

const typeList = [
  {
    type: 'purchase',
    title: $t('project.purchase'),
    content: $t('valuation.crypto.purchaseMemo')
  },
  {
    type: 'inventory',
    title: $t('valuation.inventory.inventory'),
    content: $t('valuation.crypto.inventoryMemo')
  },
  {
    type: 'sales',
    title: $t('valuation.inventory.sales'),
    content: $t('valuation.crypto.salesMemo')
  }
]

const currentType = ref('')
const showDialog = ref(false)

const getTypeTitle = (type: string) => {
  return typeList.find((item) => item.type === type)?.title || ''
}

const onClickType = (item: Record<string, string>) => {
  console.log(item)
  currentType.value = item.type
  showDialog.value = true
}

const onCloseDialog = () => {
  currentType.value = ''
}
</script>

<style lang="scss">
.elv-valuation-table-header {
  width: 100%;
  padding: 0 29px 8px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: #000;
    font-family: 'Plus Jakarta Sans';
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.elv-record-banner {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .elv-record-banner-content {
    display: flex;
    flex: 1;
    min-height: 0px;
    width: 100%;
    position: relative;
  }
}

.elv-record-dialog {
  &-title {
    padding: 15px 24px;
    flex-direction: column;
    align-items: flex-start;

    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    border-bottom: 1px solid #edf0f3;
  }

  .el-dialog__headerbtn {
    width: 54px;
    height: 54px;
  }

  .el-dialog__header {
    padding: 0;
  }
}
</style>
